<template>
  <main
    class="tourism color-primary h-100 blank-aside-js"
    :class="isOpenAside == 'true' ? 'blank-aside' : ''"
  > 
    <TourismFilter></TourismFilter>
    <BannerSection location="homepage-top"></BannerSection>
    <TourismDirection ref="tourismDirection"></TourismDirection>
    <TourismLogos></TourismLogos>
    <div class="tourism-container m-t-30">
      <h4 class="bg-grey7 d-flex align-items-center justify-content-between mx10 p5">
        <p>{{ $t( "TOURISM.FLIGHTS_SELECTED_DESTINATIONS" ) }}</p>
        <router-link
          class="color-blue10"
          :to="{
            name: 'FlightHome',
          }"
        >
          {{ $t( "TOURISM.VIEW_ALL_FLIGHTS" ) }}
        </router-link>
      </h4>
      <VueSlickCarousel
        v-bind:slidesToShow="itemPerSlide"
        class="cus-slick-carousel tourism-carousel"
        :class="deals && deals.length<4 ? sliderDirection : ''"
        :rtl="sliderDirection=='rtl' ? true : false"
      >
        <div class="px10" 
          v-for="(deal, index) in deals"
          :key="index"
        >
          <FlightTourismItem>
            <p class="fs14 text-center" slot="title">
              {{ $t( "TOURISM.FLIGHTS_TO" ) }} <b>Paris, France</b>
            </p>
            <button 
              class="primary-btn small-btn bg-blue5 df-c fs14 color-primary full-detail-btn m-t-15" 
              slot="actionPart" 
              @click="openFullDetailModal()"
            >
              <img src="/assets/img/icons/info.svg" alt="" class="">
              {{ $t( "TOURISM.FULL_DETAILS" ) }}
            </button>
            <router-link
              :to="{
                name: 'FlightAll',
              }"
              slot="routerBlock" 
            >
              <button class="primary-btn small-btn fs16 bg-3">
                {{ $t( "TOURISM.EXPLORE_MORE_DATES" ) }}
              </button>
            </router-link>
          </FlightTourismItem>
        </div>
      </VueSlickCarousel>
    </div>
    <div class="tourism-container m-t-30">
      <h4 class="bg-grey7 d-flex align-items-center justify-content-between mx10 p5">
        <p> {{ $t( "TOURISM.VACATION_PACKAGES_TO_SELECTED_DESTINATIONS" ) }}</p>
        <p class="color-blue10">{{ $t( "TOURISM.VIEW_ALL_VACATIONS_DEALS" ) }}</p>
      </h4>
      <VueSlickCarousel
        v-bind:slidesToShow="itemPerSlide"
        class="cus-slick-carousel tourism-carousel"
        :class="deals && deals.length<4 ? sliderDirection : ''"
        :rtl="sliderDirection=='rtl' ? true : false"
      >
        <div class="px10" 
          v-for="(deal, index) in deals"
          :key="index"
        >
          <FlightTourismVacationItem>
            <p class="fs14 text-center" slot="title">
              <b>{{ $t( "DASHBOARD.VACATION_PACKAGES" ) }}</b> to <b>Berlin, Germany</b>
            </p>
          </FlightTourismVacationItem>
        </div>
      </VueSlickCarousel>
    </div>
    <div class="tourism-container m-t-30">
      <h4 class="bg-grey7 d-flex align-items-center justify-content-between mx10 p5">
        <p>{{ $t( "TOURISM.ORGANIZED_TOURS_TO_SELECTED_DESTINATIONS" ) }}</p>
        <p class="color-blue10">{{ $t( "TOURISM.VIEW_ALL_ORGANIZED_DEALS" ) }}</p>
      </h4>
      <VueSlickCarousel
        v-bind:slidesToShow="itemPerSlide"
        class="cus-slick-carousel tourism-carousel"
        :class="deals && deals.length<4 ? sliderDirection : ''"
        :rtl="sliderDirection=='rtl' ? true : false"
      >
        <div class="px10" 
          v-for="(deal, index) in deals"
          :key="index"
        >
          <FlightTourismVacationItem>
            <p class="fs14 text-center" slot="title">
              <b>{{ $t( "TOURISM.ORGANIZED_TOUR" ) }}</b> to <b>Greece</b>
            </p>
          </FlightTourismVacationItem>
        </div>
      </VueSlickCarousel>
    </div>
    <FullDetailModal></FullDetailModal>
  </main>
</template>

<script>
import BannerSection from "../../../components/BannerSection";
import TourismFilter from "../../../components/tourism/TourismFilter.vue";
import TourismDirection from "../../../components/tourism/TourismDirection";
import TourismLogos from "../../../components/tourism/TourismLogos";
import FlightTourismItem from "../../../components/tourism/flight/FlightTourismItem.vue";
import FlightTourismVacationItem from "../../../components/tourism/flight/FlightTourismVacationItem.vue";
import FullDetailModal from "../../../components/tourism/flight/FullDetailModal.vue";

export default {
  name: "FligntHomePage",
  components: {
    BannerSection,
    TourismFilter,
    TourismDirection,
    TourismLogos,
    FlightTourismItem,
    FlightTourismVacationItem,
    FullDetailModal
  },
  data() {
    return {
      deals: [{},{},{},{},{},{},{},{},{},{},{},{}]
    }
  },
  methods: {
    openFullDetailModal() {
      this.$modal.show("FullDetailModal");
    }
  }
}
</script>

<style lang="scss">
@import "../../../assets/scss/tourism.scss";
</style>
