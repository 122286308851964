<template>
  <div class="d-flex justify-content-between px20 w-100 fs16">
    <div class="text-center">
      <p>
        <b>Tel-Aviv</b>(TLV)
      </p>
      <p>
        <b>14/04/21</b>
      </p>
      <div class="d-flex justify-content-center fs12">
        <img src="/assets/img/icons/plane-departure-light-black.svg" alt="" class="mx5">
        12:45
      </div>
      <div class="df-c">
        <img src="/assets/img/icons/flight connection.svg" alt="">
      </div>
    </div>
    <div class="df-c flex-column">
      <RateStar :starNumber="5"></RateStar>
      <img src="/assets/img/icons/flight.svg" alt="">
      <p>5 Days</p>
      <slot name="additionalData">
      </slot>
    </div>
    <div class="text-center">
      <p>
        <b>Berlin</b>(BRL)
      </p>
      <p>
        <b>18/04/21</b>
      </p>
      <div class="d-flex justify-content-center fs12">
        <img src="/assets/img/icons/plane-departure-light-black.svg" alt="" class="mx5">
        18:45
      </div>
      <div class="df-c">
        <img src="/assets/img/icons/flightDirect.svg" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import RateStar from '../RateStar.vue';
export default {
  name: "FlightDesDataStar",
  props: {
  },
  components: {
    RateStar
  }

}
</script>

<style lang="scss" scoped>
.destination__star {
  width: 12px;
  height: 12px;
}
</style>