<template>
  <section class="tourism__direction tourism__logo align-items-center">
    <p class="weight-700 working-with text-center">{{ $t("TOURISM.WORKING_WITH") }}</p>
    <div class="d-flex align-items-center justify-content-between flex-wrap logos">
      <img class="logo__img" src="/assets/img/logo/Arkia_logo.svg" alt />
      <img class="logo__img" src="/assets/img/logo/Israir_Airlines_Logo.svg.png" alt />
      <img class="logo__img" src="/assets/img/logo/logo.png" style="height: 30px" alt />
      <img class="logo__img" src="/assets/img/logo/1920px-EshetTours.svg.png" style="height: 25px" alt />
      <img class="logo__img" src="/assets/img/logo/קשרי1.png" alt />
      <img class="logo__img" src="/assets/img/logo/קווי.png" alt />
    </div>
  </section>
</template>

<script>
export default {
  name: 'TourismLogos',
}
</script>

<style lang="scss" scoped>
.tourism__logo {
  height: unset;
  flex-wrap: nowrap;
  .logo__img {
    height: 38px;
    margin: 10px;
  }
  .working-with {
    margin-left: 30px;
  }
  .logos {
    width: 100%;
  }
}

 @media screen and (max-width: 767px) {
  .tourism__logo {
    .logo__img {
      height: 20px !important;
      margin: 10px;
    }
  }
}

.ltr-type {
  .tourism__logo {
    .working-with {
      margin-left: unset;
      margin-right: 30px;
    }
  }
}
</style>