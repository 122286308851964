<template>
  <div>
    <div class="df-c p-relative card-header tourism-category__place" style="background-image: url('/assets/img/berlin2.png')">
      <h3 class="tourism-category__place-name fs23">Cairo</h3>
      <CategoryShare></CategoryShare>
      <CategoryStar></CategoryStar>
    </div>
    <div class="card-body bg-white p-b-5">
      <slot name="title"></slot>
      <FlightDesDataStar class="m-t-10">
        <template slot="additionalData">
          <button class="primary-btn small-btn bg-blue5 df-c fs14 color-primary full-detail-btn m-t-15">
            {{ $t( "TOURISM.GO_TO_DEAL" ) }}
          </button>
        </template>
      </FlightDesDataStar>
      <div class="d-flex flex-column align-items-center">
        <div class="m-t-20 fs14 text-center">
          Starting from: <b class="fs16">300 ₪</b>
        </div>
        <button class="primary-btn small-btn fs16 bg-3">
          {{ $t( "TOURISM.EXPLORE_MORE_DATES" ) }}
        </button>
        <p class="fs11 m-t-5">
          * {{ $t( "TOURISM.PRICE_SPECIFIC_FLIGHT_SHOWN" ) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import RateStar from '../../RateStar';
import CategoryShare from '../../CategoryShare';
import CategoryStar from '../CategoryStar';
import FlightDesDataStar from '../FlightDesDataStar';
export default {
  name: "FlightTourismVacationItem",
  components: {
    RateStar,
    CategoryShare,
    CategoryStar,
    FlightDesDataStar
  },
}
</script>

<style lang="scss" scoped>
  .card-header {
    height: 180px;
    background-size: cover;
  }
  .fs16 {
    font-size: 16px !important;
  }
  .full-detail-btn {
    padding: 0 3px;
    img {
      margin-right: 5px;
      width: 17px;
    }
  }
</style>